import firebaseInitialization from "../components/Firebase/Firebase.init";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword
} from "firebase/auth";
import { useState } from "react";
import Swal from "sweetalert2";
import { post_firebase_sso } from "../api/apiConfig";
import { getToken } from "../localstorage";
import axios from "axios";
import { syncRemidiProfileViaServer } from "../api/remidi"
firebaseInitialization();

const useFirebase = () => {
  const [user, setUser] = useState({});
  const auth = getAuth();

  // login with google
  const googleProvider = new GoogleAuthProvider();
  const googleSignIn = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        const postData = {
          email: user.email,
          token: user.stsTokenManager.accessToken,
        };
        /****************************************/
        // firebase php
        axios
          .post(post_firebase_sso, postData, {
            headers: {
              "Content-Type": "application/json",
              "X-API-KEY": "kpjhealth",
              Authorization: `Bearer ${getToken()}`,
            },
          })
          .then((response) => {
            const { data } = response;
            if (data.status) {
              const { account } = data;
              localStorage.setItem("token", data.token);
              localStorage.setItem("firstname", account.firstname);
              localStorage.setItem("patient_id", account.user_id);
              localStorage.setItem("patient_nric", account.nric);
              // sync with remidi
              syncRemidiProfileViaServer(user.uid, account).then((res) => {
              })
              .finally(() => {
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
            } else {
              Swal.fire({
                icon: "error",
                title: "Login",
                text: "Invalid email or password. Please try again.",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Login",
              text: error.message || "An error occurred during login.",
            });
          });
        /****************************************/
      })
      .catch((error) => {
        const ignore_these_codes = [
          'auth/popup-closed-by-user',
          'auth/cancelled-popup-request',
        ]
        // dont show error if user cancelled themselves
        if(!ignore_these_codes.includes(error.code)){
          const errorMessage = error.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errorMessage,
          });
        }
      });
  };

  //   login with facebook
  const facebookProvider = new FacebookAuthProvider();
  const facebookSignIn = () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => {
        const user = result.user;
      })
      .catch((error) => {
        const errorMessage = error.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
        });
      });
  };

  // firebase login
  const emailSignIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
    .then((res) => {
      const user = res.user;

      const postData = {
        email: user.email,
        token: user.stsTokenManager.accessToken,
      };

      /****************************************/
      // firebase php
      axios
        .post(post_firebase_sso, postData, {
          headers: {
            "Content-Type": "application/json",
            "X-API-KEY": "kpjhealth",
            Authorization: `Bearer ${getToken()}`,
          },
        })
        .then((response) => {
          const { data } = response;
          if (data.status) {
            const { account } = data;
            localStorage.setItem("token", data.token);
            localStorage.setItem("firstname", account.firstname);
            localStorage.setItem("patient_id", account.user_id);
            localStorage.setItem("patient_nric", account.nric);
            // sync with remidi
            syncRemidiProfileViaServer(user.uid, account).then((res) => {
            })
            .finally(() => {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
          } else {
            Swal.fire({
              icon: "error",
              title: "Login",
              text: "Invalid email or password. Please try again.",
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Login",
            text: error.message || "An error occurred during login.",
          });
        });
      /****************************************/
    })
    .catch((error) => {
      console.log({error})
      let msg = error.message || "An error occurred during login."
      if(error?.code == 'auth/invalid-credential'){
        msg = 'Invalid Credential';
      }
      Swal.fire({
        icon: "error",
        title: "Login",
        text: msg,
      });
    })
  }

  // firebase register
  const emailRegister = async (email, password) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      const user = result.user;
      return {
        status: true,
        message: 'Registered successfully in Firebase',
        data: user
      };
    } catch (error) {
      return {
        status: false,
        reason: error?.message || 'Something went wrong, please try again later.',
        data: error
      };
    }
  };

  // firebase forgot password
  const forgotPasswordFirebase = async (email) => {
    try{
      await sendPasswordResetEmail(auth, email)
      return {
        status: true,
        message: `Password reset link has been sent to ${email}`,
      }
    } catch (error) {
      return {
        status: false,
        reason: error?.message || 'Something went wrong, please try again later.',
        data: error
      };
    }
  }

  const changeFirebasePassword = async (newPassword) => {
    const user = auth.currentUser;
    if (!user) {
      return;
    }
    updatePassword(user, newPassword)
      .then(() => {
        console.log("successfully updated password to firebase!")
      })
      .catch((error) => {
        console.error("fail to update password to firebase")
      });
  }
  

  return {
    user,
    googleSignIn,
    facebookSignIn,
    emailSignIn,
    emailRegister,
    forgotPasswordFirebase,
    changeFirebasePassword
  };
};
export default useFirebase;
