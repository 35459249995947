import { useEffect, useState } from "react";
import { api_get_third_party_scripts } from "../api/hospital";

export default function useThirdPartyScript() {
  const [thirdPartyScript, setThirdPartyScript] = useState({});

  async function fetchThirdPartyScript({ hospital_id = null, location = null, clinic_id = null, clinic_location = null }) {
    try {
      const res = await api_get_third_party_scripts({ hospital_id, location, clinic_id, clinic_location });
      if (res.status) {
        setThirdPartyScript({ ...res.data, hospital_id, location, clinic_id, clinic_location });
      }
    } catch (error) {
      console.error("Error fetching third party scripts:", error);
    }
  }

  useEffect(() => {
    const headScripts = [];
    const bodyScripts = [];

    if (Object.entries(thirdPartyScript).length) {

      const tagName = () => {
        const { hospital_id, location, clinic_id, clinic_location } = thirdPartyScript;
        if (hospital_id) {
          return `hospital_${hospital_id}`;
        }
        if (location) {
          return `${location.toLowerCase().replaceAll(" ", "_")}`
        }
        if (clinic_id) {
          return `clinic_${clinic_id}`;
        }
        if (clinic_location) {
          return `${clinic_location.toLowerCase().replaceAll(" ", "_")}`
        }
        return "general"
      }

      const tempHead = document.createElement("div");
      tempHead.innerHTML = thirdPartyScript.script;
      while (tempHead.firstChild) {
        try {
          // tempHead.firstChild.setAttribute("name", `${ thirdPartyScript.hospital_id || thirdPartyScript.location || thirdPartyScript.clinic_id || thirdPartyScript.clinic_location || "general" }_tps`);
          tempHead.firstChild.setAttribute("name", `${tagName()}_tps`);
        } catch (e) {}
        headScripts.push(tempHead.firstChild);
        tempHead.firstChild.remove();
      }
      headScripts.forEach((headScript) => {
        document.head.appendChild(headScript);
      });

      const tempBody = document.createElement("body");
      tempBody.innerHTML = thirdPartyScript.body_script;
      while (tempBody.firstChild) {
        try {
          // tempBody.firstChild.setAttribute("name", `${ thirdPartyScript.hospital_id || thirdPartyScript.location || thirdPartyScript.clinic_id || thirdPartyScript.clinic_location || "general" }_tps`);
          tempHead.firstChild.setAttribute("name", `${tagName()}_tps`);
        } catch (e) {}
        bodyScripts.push(tempBody.firstChild);
        tempBody.firstChild.remove();
      }
      bodyScripts.reverse().forEach((bodyScript) => {
        document
          .getElementById("root")
          .insertBefore(bodyScript, document.getElementById("root").firstChild);
      });
    }
    return () => {
      headScripts.forEach((script) => script?.remove());
      bodyScripts.forEach((script) => script?.remove());
    };
  }, [thirdPartyScript]);

  return { fetchThirdPartyScript };
}
